









import Vue from 'vue'
import PlaidInitializer from '@components/plaid/initializer.vue'
import ManualFlow from './ManualFlow.vue'

export default Vue.extend({
  data() {
    return {
      showManualFlow: false as boolean,
    }
  },
  components: {
    PlaidInitializer,
    ManualFlow,
  },
  methods: {
    toggleOnManualFlow(): void {
      this.showManualFlow = true
      this.$nextTick(() => this.handleScroll())
    },
    handleScroll() {
      const target = document.querySelector('.addy-business-account-manual-flow')
      target && target.scrollIntoView({ behavior: 'smooth' })
    },
  },
})
