





































import Vue from 'vue'
import connectBankAccountMixin from '@utils/mixins/connect-bank-account-mixin'
import BankAutocomplete from '@components/input/bank-autocomplete.vue'
import AccountFields from '@views/wallet/accounts/manual/AccountFields.vue'
import UploadButton from '@components/upload/button.vue'
import PadAgreement from './PadAgreement.vue'
import SuccessModal from './SuccessModal.vue'
import { encodeFileAsUrl } from '@utils/common-methods/common'
import { connectAccount } from '@api/addy-plus/wallet'
import { IData, IMethods, IComputed, FieldType } from '@/types/addy_plus/corporations/wallet/ManualFlow'

export default Vue.extend<IData, IMethods, IComputed>({
  // @ts-ignore
  mixins: [connectBankAccountMixin],
  data() {
    return {
      bank: '',
      transitNumber: undefined,
      institutionNumber: undefined,
      accountNumber: undefined,
      chequeFile: null,
      previewUrl: '',
      isLinking: false,
      showSuccessModal: false,
    }
  },
  components: {
    BankAutocomplete,
    AccountFields,
    UploadButton,
    PadAgreement,
    SuccessModal,
  },
  computed: {
    accountId() {
      return this.$route.params.accountId
    },
    corporationId() {
      return this.$route.params.corporationId
    },
  },
  methods: {
    onAccountFieldInput(field, value) {
      this[field as FieldType] = value
    },
    uploadCheque(event: Event) {
      this.chequeFile = (event.target as HTMLInputElement).files?.[0] || null
      encodeFileAsUrl(this.chequeFile as File, (url) => {
        this.previewUrl = url
      })
    },
    linkAccount() {
      this.isLinking = true

      const basePayload = {
        account_name: this.bank,
        transit_number: this.transitNumber,
        routing_number: this.institutionNumber,
        account_number: this.accountNumber,
        is_personal_account: false, // TODO: need to confirm this
      }

      const params = this.handlePayloadForLinkAccount({ type: 'manual', basePayload, chequeFile: this.chequeFile })
      connectAccount(this.corporationId, params).then((data) => {
        this.isLinking = false
        if (!data.success) return

        this.showSuccessModal = true
      })
    },
  },
})
